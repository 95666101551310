<!--服务设置-->
<template>
  <div style="padding: 20px;">
    <div style="display: flex;justify-content: space-between;align-items: center;">
      <div>
        <span style="font-size: 24px;font-weight: 500;color: #707070;">服务设置</span>
      </div>
      <div>

      </div>
    </div>

    <el-card shadow="never" style="margin-top: 20px;">
      <el-form ref="form" :model="form" label-width="300px">
        <div style="text-align: center;margin-top: 10px;">
          <span style="font-size: 30px;font-weight: 500;color: #000000;">服务配置</span>
        </div>

        <el-row :gutter="30" style="margin-top: 20px;display: flex;align-items: center;" v-for="(item,index) in List" :key="index">
          <el-col :span="12">
            <el-form-item :label="item.label" style="margin-bottom: 0!important;">
              <el-input clearable placeholder="请输入" v-model="item.values"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div style='margin-left: 30px;'>
              <el-checkbox v-model="item.if_alter">是否可以修改数量</el-checkbox>
            </div>
          </el-col>
        </el-row>
        <!--<el-row :gutter="30" style="margin-top: 20px;display: flex;align-items: center;">-->
        <!--  <el-col :span="12">-->
        <!--    <el-form-item label="服务商开通投标信息服务费（年）：" style="margin-bottom: 0!important;">-->
        <!--      <el-input clearable placeholder="\请输入" v-model="form.input2"/>-->
        <!--    </el-form-item>-->
        <!--  </el-col>-->
        <!--  <el-col :span="12">-->
        <!--    <div style='margin-left: 30px;'>-->
        <!--      <el-checkbox v-model="form.checked">是否可以修改数量</el-checkbox>-->
        <!--    </div>-->
        <!--  </el-col>-->
        <!--</el-row>-->
        <!--<el-row :gutter="30" style="margin-top: 20px;display: flex;align-items: center;">-->
        <!--  <el-col :span="12">-->
        <!--    <el-form-item label="服务商开通做标书服务费（元/个）：" style="margin-bottom: 0!important;">-->
        <!--      <el-input clearable placeholder="\请输入" v-model="form.input2"/>-->
        <!--    </el-form-item>-->
        <!--  </el-col>-->
        <!--  <el-col :span="12">-->
        <!--    <div style='margin-left: 30px;'>-->
        <!--      <el-checkbox v-model="form.checked">是否可以修改数量</el-checkbox>-->
        <!--    </div>-->
        <!--  </el-col>-->
        <!--</el-row>-->
        <!--<el-row :gutter="30" style="margin-top: 20px;display: flex;align-items: center;">-->
        <!--  <el-col :span="12">-->
        <!--    <el-form-item label="服务商购买虚拟号段费（10个）：" style="margin-bottom: 0!important;">-->
        <!--      <el-input clearable placeholder="\请输入" v-model="form.input2"/>-->
        <!--    </el-form-item>-->
        <!--  </el-col>-->
        <!--  <el-col :span="12">-->
        <!--    <div style='margin-left: 30px;'>-->
        <!--      <el-checkbox v-model="form.checked">是否可以修改数量</el-checkbox>-->
        <!--    </div>-->
        <!--  </el-col>-->
        <!--</el-row>-->
        <!--<el-row :gutter="30" style="margin-top: 20px;display: flex;align-items: center;">-->
        <!--  <el-col :span="12">-->
        <!--    <el-form-item label="服务商开通电子工单模板服务费：" style="margin-bottom: 0!important;">-->
        <!--      <el-input clearable placeholder="\请输入" v-model="form.input2"/>-->
        <!--    </el-form-item>-->
        <!--  </el-col>-->
        <!--  <el-col :span="12">-->
        <!--    <div style='margin-left: 30px;'>-->
        <!--      <el-checkbox v-model="form.checked">是否可以修改数量</el-checkbox>-->
        <!--    </div>-->
        <!--  </el-col>-->
        <!--</el-row>-->
        <!--<el-row :gutter="30" style="margin-top: 20px;display: flex;align-items: center;">-->
        <!--  <el-col :span="12">-->
        <!--    <el-form-item label="服务商开通客户的API司机接口（年/个）：" style="margin-bottom: 0!important;">-->
        <!--      <el-input clearable placeholder="\请输入" v-model="form.input2"/>-->
        <!--    </el-form-item>-->
        <!--  </el-col>-->
        <!--  <el-col :span="12">-->
        <!--    <div style='margin-left: 30px;'>-->
        <!--      <el-checkbox v-model="form.checked">是否可以修改数量</el-checkbox>-->
        <!--    </div>-->
        <!--  </el-col>-->
        <!--</el-row>-->
        <!--<el-row :gutter="30" style="margin-top: 20px;display: flex;align-items: center;">-->
        <!--  <el-col :span="12">-->
        <!--    <el-form-item label="服务商购买虚拟司机（10个）：" style="margin-bottom: 0!important;">-->
        <!--      <el-input clearable placeholder="\请输入" v-model="form.input2"/>-->
        <!--    </el-form-item>-->
        <!--  </el-col>-->
        <!--  <el-col :span="12">-->
        <!--    <div style='margin-left: 30px;'>-->
        <!--      <el-checkbox v-model="form.checked">是否可以修改数量</el-checkbox>-->
        <!--    </div>-->
        <!--  </el-col>-->
        <!--</el-row>-->
        <!--<el-row :gutter="30" style="margin-top: 20px;display: flex;align-items: center;">-->
        <!--  <el-col :span="12">-->
        <!--    <el-form-item label="抢单司机最大派单范围（公里）：" style="margin-bottom: 0!important;">-->
        <!--      <el-input clearable placeholder="\请输入" v-model="form.input2"/>-->
        <!--    </el-form-item>-->
        <!--  </el-col>-->
        <!--</el-row>-->
        <!--<el-row :gutter="30" style="margin-top: 20px;display: flex;align-items: center;margin-bottom: 20px;">-->
        <!--  <el-col :span="12">-->
        <!--    <el-form-item label="服务商接单服务费（元/单）：" style="margin-bottom: 0!important;">-->
        <!--      <el-input clearable placeholder="\请输入" v-model="form.input2"/>-->
        <!--    </el-form-item>-->
        <!--  </el-col>-->
        <!--</el-row>-->

      </el-form>
    </el-card>

    <div style="margin-top: 20px;text-align: center;">
      <el-button
        style="margin-left: 20px;width: 240px;"
        type="primary"
        v-auths="[`${$config.uniquePrefix}operate:serviceSettings:put`]"
        @click="save()">提交
      </el-button>
    </div>

  </div>
</template>

<script>
import {getget_service_setup, Saveset_service_setup} from "@/api/operate";

export default {
  data(){
    return{
      form:{},
      List:[]
    }
  },

  mounted() {
    // 获取配置
    this.getConfig();
  },

  methods:{
    //获取配置
    getConfig(){
      getget_service_setup().then(res => {
        console.log(res);
        this.List = res.data;
      })
    },

    //保存
    save(){
      // let data = JSON.stringify(this.List);
      let data = {
        data: this.List
      };
      Saveset_service_setup(data).then(res => {
        if (res.code === 200) {
          this.$message.success(res.msg);
          this.getConfig();
        }else {
          this.$message.error(res.msg);
        }
      }).catch(e => {
        this.$message.error(e.msg);
      })
    },

  },

}
</script>

<style lang="scss" scoped>

</style>
